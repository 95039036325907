import Events from '@/utils/Events'

class Utils {
    
    constructor() {
        this.events = {}
        this.deviceReady = false;
        this.vueMounted = false;

        document.addEventListener('deviceready', this.handleDeviceReady, false);

        if (window.webkit && window.webkit.messageHandlers) {
            let cordovaClientScript = document.createElement("script");
            cordovaClientScript.setAttribute("src", "cordova.js");
            document.body.appendChild(cordovaClientScript);
        } else {
            var self = this;
            setTimeout(function() {
                self.handleDeviceReady();
            },500);
        }       
        
        String.prototype.descript = function(){
            var text = this;
            var SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
            while (SCRIPT_REGEX.test(text)) text = text.replace(SCRIPT_REGEX, "");    
            return text;
        }
                
    }

    getUUID(){
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }     

    getTimeFromDate(dateObj){
        //return dateObj.toISOString().split('T')[1].substring(0,5);
        return dateObj.toLocaleTimeString().substring(0,5);
    }

    getParameterByName(name, url = window.location.href) {
        name = name.replace(/[[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }    

    parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    handleDeviceReady() {
        console.log("DEVICEREADY");                          
        this.deviceReady = true
        if (this.vueMounted) {            
            Events.trigger("appReady");            
        }
    }

    setMounted() {
        this.vueMounted = true;
    }
    
    
}

export default new Utils()
    

