<script>    
    import SelectButtons from '@/components/inputs/SelectButtons.vue'      
    import Popover from '@/components/units/Popover.vue'
    import GlobalMixin from '@/utils/Global.mixin' 

    export default {
        mixins: [GlobalMixin],        
        props: ['sortOptions'],   
        data:function(){
            return {
                filter: {
                    sort:1,
                    status:0,
                },
                filterSort:[
                    { id:1, name:"Name"},
                    { id:2, name:"Status"},
                    { id:3, name:"Date"}
                ],            
                filterStatus:[
                    { id:0, name:"All"},
                    { id:2, name:"Install"},
                    { id:3, name:"Done"}
                ]
            }
        },
        components:{
            SelectButtons,Popover
        },
        methods: {
            closePopover() {
                this.$emit('close');
            }
        },             
        created() {
            console.log("PooverFilter CREATED");            
        }        
    }
</script>

<template>

    <popover id="filter" title="Filter and sorting" icon="filter">

        <div class="field">
            <h3>Sort by</h3>                
            <select-buttons :items="filterSort" v-model="filter.sort"></select-buttons> 
        </div> 
            
        <div class="field">
            <h3>Filter by Status</h3>                
            <select-buttons :items="filterStatus" v-model="filter.status"></select-buttons> 
        </div>        

    </popover>                            

</template>    