import Events from '@/utils/Events'
import Utils from '@/utils/Utils'

var msalClientId = '5ec3e6ca-643f-46cd-8097-2630096900af';
var msalTenantId = 'b460c35d-148f-4708-a8c9-d0cb7605ab7b';

var msalWebConfig = {
    auth: {
        clientId: msalClientId,
        authority: "https://login.microsoftonline.com/" + msalTenantId
    }
};     

var msalOptions = {
    authorizationUserAgent: 'DEFAULT',
    multipleCloudsSupported: false,
    brokerRedirectUri: false,
    accountMode: 'SINGLE',
    scopes: ['api://' + msalClientId + '/access_as_user'],
    authorities: [
        {
            type: 'AAD',
            audience: 'AzureADMyOrg',
            authorityUrl: '',
            cloudInstance: 'MSALAzurePublicCloudInstance',
            default: true
        }
    ]
};

var Msal = window.Msal;

export default {     
    data: {
        token:localStorage.getItem("token"),  
        msalWebLoginRequest : { scopes: [msalClientId+ "/access_as_user","offline_access"] }              
    },
    methods : {      
        loginInit:function(callback) {
            console.log("LOGIN INIT", msalOptions);
            if (typeof window.cordova != "undefined") {                            
                window.cordova.plugins.msalPlugin.msalInit(callback,function(err){
                    console.error(err);
                }, msalOptions);
            }
        },
        logout:function(callback) {
            if (this.isApp) {
                window.cordova.plugins.msalPlugin.signOut(
                    function(msg) {
                        if (typeof callback!="undefined") callback(true, msg);
                    }, 
                    function(err) {
                        if (typeof callback!="undefined") callback(false, err);
                    }
                );
            } else {                
                sessionStorage.clear();
                document.location.reload();
            }
        },
        loginSilent:function(callback) {            
            if (this.isApp) {     
                this.loginInit(function() {    
                    Events.trigger("loginStarted");       
                    window.cordova.plugins.msalPlugin.signInSilent(
                        function(token) {                            
                            Events.trigger("token",token);
                            localStorage.setItem("token",token);
                            var jwt = Utils.parseJwt(token);
                            console.log("UPDATED TOKEN", jwt);      
                            Events.trigger("loginEnded",true);                  
                            if (typeof callback!="undefined") callback(true);
                        },
                        function(err) {
                            console.error(err);
                            Events.trigger("loginEnded",false);
                            if (typeof callback!="undefined") callback(false);      
                        }
                    );
                });
            } else {
                const msalInstance = new Msal.UserAgentApplication(msalWebConfig); 
                var currentAccount = msalInstance.getAccount();            
                if (currentAccount) {
                    Events.trigger("loginStarted");
                    msalInstance.ssoSilent({ loginHint: currentAccount.userName}).then(response => {                        
                        var token = response.idToken.rawIdToken;                    
                        Events.trigger("token",token);
                        localStorage.setItem("token",token);    
                        var jwt = Utils.parseJwt(token);                                            
                        console.log("SILENT LOGIN OK", jwt);
                        Events.trigger("loginEnded",true); 
                        if (typeof callback!="undefined") callback(true);
                    })
                    .catch(error => {
                        console.log("SILENT LOGIN ERROR", error);
                        Events.trigger("loginEnded",false); 
                        if (typeof callback!="undefined") callback(false);
                    }); 
                } else {
                    Events.trigger("loginEnded",false); 
                    if (typeof callback!="undefined") callback(false);
                }        
            }
        },
        loginInteractive:function(callback) {
            console.warn("loginInteractive");
            if (this.isApp) {                                
                this.loginInit(function() {
                    Events.trigger("loginStarted");
                    window.cordova.plugins.msalPlugin.signInInteractive(
                        function(token) {                            
                            localStorage.setItem("token",token);
                            Events.trigger("token",token);
                            var jwt = Utils.parseJwt(token);                            
                            console.log("UPDATED TOKEN", jwt);      
                            Events.trigger("loginEnded",true);                  
                            if (typeof callback!="undefined") callback(true);
                        },
                        function(err) {
                            console.error(err);
                            Events.trigger("loginEnded",false);
                            if (typeof callback!="undefined") callback(false);      
                        }
                    );
                });
            }
            else {           
                Events.trigger("loginStarted");
                var msalInstance = new Msal.UserAgentApplication(msalWebConfig);
                msalInstance.handleRedirectCallback((error, response) => {
                    console.error(error, response);
                });                                   
                msalInstance.loginPopup(this.msalWebLoginRequest).then(response => {                    
                    var token = response.idToken.rawIdToken;
                    localStorage.setItem("token",token);
                    Events.trigger("token",token);                    
                    var jwt = Utils.parseJwt(token);
                    console.log("UPDATED TOKEN INTERACTIVE", jwt);   
                    Events.trigger("loginEnded",true);                 
                    if (typeof callback!="undefined") callback(true);                    
                }).catch(err => {
                    console.error(err);      
                    localStorage.removeItem("token");
                    Events.trigger("loginEnded",false);   
                    if (typeof callback!="undefined") callback(false);                               
                });  
            }
        }
    }
};
 