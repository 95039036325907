
<script>
    import Api from '@/utils/Api'
    import BeaconConfig from '@/utils/BeaconConfig'
    //import Events from '@/utils/Events'   
    import GlobalMixin from '@/utils/Global.mixin'  
    import moment from 'moment'

    export default {        
        props:['pageId'],
        mixins: [GlobalMixin,BeaconConfig],
        data() {
            return {
                loadingVehicle:false,
                vehicleData:{},                
                sensorTypes: {
                    2: "Beacon"
                }
            }
        },      
        methods: {
            init:function() {
                console.warn("PageVehicle INIT", this.currentPage);                    
                this.vehicleData = this.currentPage.pageData;
                this.loadingVehicle = true;
                var self = this;
                Api.getVehicle(this.vehicleData.id, function(vehicleData) {
                    console.log("vehicleData",vehicleData);
                    self.vehicleData = vehicleData;
                    self.loadingVehicle = false;
                });                
            },
            getStatus:function(sensor) {
                if (sensor.state==10) return "red";
                else if (sensor.state==1) return "yellow";
                else if (sensor.state==5) return "green";
                return "gray";
            },
            getSensorType:function(sensor) {
                if (Object.prototype.hasOwnProperty.call(this.sensorTypes, sensor.sensorType)) return this.sensorTypes[sensor.sensorType];
                return "Generic";
            },
            editVehicleClick:function() {
                this.showPopover("editVehicle",this.vehicleData);
            },
            editSensorClick:function(sensor) {                
                this.showPage("editSensor",sensor);
            },
            addSensorClick:function() {
                console.log("ADD SENSOR");                
            },
            configureBeacon:function(sensor) {
                console.log("CONFIGURE BEACON",sensor);
            },
            getBatteryPercentage:function(sensor) {
                var now = moment();
                var expectedLifeTimeDays = 365*5;
                var installDate = moment(sensor.installation.installDate);
                var daysInOperation = now.diff(installDate, 'days');                
                var p = Math.round(100 * (1 - (daysInOperation/expectedLifeTimeDays)));
                if (p<0) p = 0;
                if (installDate.year()<2000) p = -1;                
                return p;
            },
            getBatteryLevelClass:function(sensor) {
                var level = this.getBatteryPercentage(sensor);                
                if (level < 0) return "hide";
                if (level < 20) return "p20";
                if (level < 40) return "p40";
                if (level < 60) return "p60";
                if (level < 80) return "p80";                
                return "p100";                
            },
            expandSensor:function(sensor) {
                console.log("expandSensor",sensor);                
                this.vehicleData.sensors.map((s)=> { if (s.id!=sensor.id) s.expanded = false; });
                sensor.expanded=!(sensor.expanded);
            }
        },    
        watch: {           
            'currentPage': function (){                
                if (this.currentPage.id==this.pageId) this.init();    
            },                                               
        },              
        created() {           
            console.log("PageVehicle CREATED");                                                                         
        }           
    }
</script>

<template>

    <div :id="pageId" class="page" :class="{ active: currentPage.id==pageId, previous:previousPage.id==pageId }">

        
        <div class="header-option">
            <h2>Vehicle Information</h2>
            <div @click="editVehicleClick()" class="clickpadding">
                <img class="icon" src="/img/icon-edit.svg">
                <span class="link">Edit</span>
            </div>
        </div>            
    
        <div class="table mb-1">
            <div class="row">    
                <div class="col">
                    <strong>{{vehicleData.internalIdentifier||"-"}}</strong>
                    <span>Identity</span>                    
                </div>       
                <div class="col">
                    <strong>{{vehicleData.registrationNumber?vehicleData.registrationNumber.toUpperCase():"-"}}</strong>
                    <span>Registration Number</span>                        
                </div>                         
            </div>
            <div class="row">                    
                <div class="col">
                    <strong>{{vehicleData.manufacturer||"-"}}</strong>
                    <span>Vehicle Brand</span>                        
                </div>
                <div class="col">
                    <strong>{{vehicleData.model||"-"}}</strong>
                    <span>Vehicle Model</span>                        
                </div>                            
            </div>   
        </div>        
                   

        <div v-if="!loadingVehicle && !navigation.animating">

            <div class="header-option mt-2">
                <h2>Vehicle Sensors</h2>
                <div @click="addSensorClick()" class="clickpadding">
                    <img class="icon" src="/img/icon-plus.svg">
                    <span class="link">Add Sensor</span>
                </div>
            </div>   

            <div class="list expandable mt-1 mb-1">
                <div class="item" v-for="sensor in vehicleData.sensors" :key="sensor.id" :class="{ expanded: sensor.expanded }">   
                    <div class="main" @click="expandSensor(sensor)">
                        <div class="status edge2" :class="getStatus(sensor)"></div>
                        <div class="description">
                            <div class="title">{{getSensorType(sensor)}}</div>
                            <div class="smalltext">Sensor Type</div>
                        </div>      
                        <div class="extra">                            
                            <div class="title">{{sensor.name}}</div>
                            <div class="smalltext">Name</div>                            
                        </div>                                                                   
                        <div :class="{ 'down':!sensor.expanded,'up':sensor.expanded }" class="icon arrow"></div>       
                    </div>     
                    <div class="expand">
                        <div class="p05 flex" v-if="sensor.state==1">
                            <button class="mt-0 green mr-05" @click="configureBeacon(sensor)">
                                <img src="/img/icon-qr.svg">
                                Start Config
                            </button>
                            <button class="mt-0 outline" @click="editSensorClick(sensor)">
                                <img src="/img/icon-settings.svg">
                                Manual Config
                            </button>                                
                        </div>
                        <div class="table" v-if="sensor.state==5">
                            <div class="row">
                                <div class="col ml-05">
                                    <strong>{{formatDate(sensor.installation.installDate)}}</strong>
                                    <span>Installed</span>                        
                                </div>                                                         
                                <div class="col">
                                    <strong>{{formatDate(sensor.installation.installDate)}}</strong>
                                    <span>Last Ping</span>                        
                                </div>                                       
                            </div>
                            <div class="row">    
                                <div class="col ml-05">
                                    <strong>{{powerOptions[sensor.installation.transmitPower]}}</strong>
                                    <span>Range</span>                    
                                </div>       
                                <div class="col">
                                    <strong>{{transmitIntervals[sensor.installation.advertisingInterval]}}</strong>
                                    <span>Interval</span>                        
                                </div>   
                                <div class="col">                  
                                    <div class="batteryInfo" :class="getBatteryLevelClass(sensor)">                      
                                        <div class="batteryLevelBlocked"></div>
                                        <span>Battery <span class="blackText">{{getBatteryPercentage(sensor)}}%</span></span>                         
                                    </div>
                                </div>                                      
                                <div class="col" style="display:none">                                                                                
                                    <div class="batteryLevel">                                            
                                        <div class="level" :class="getBatteryLevelClass(sensor)" :style="{width:getBatteryPercentage(sensor)+'%'}"></div>                                                                                            
                                    </div>
                                    <span>Battery <span class="blackText">{{getBatteryPercentage(sensor)}}%</span></span>                         
                                </div>                                                                                              
                            </div>
                            <div class="row">
                                <div class="col">
                                    <button class="outline mt-0" @click="editSensorClick(sensor)">
                                        <img src="/img/icon-settings.svg">
                                        Change Configuration
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>                
            </div>                     
        </div>

        <div class="page-loader" v-if="loadingVehicle && !navigation.animating">
            <img src="/img/icon-loader.svg">    
        </div>        


    </div>    


    <div class="toolbar" :class="{ active: currentPage==pageId && false }">
        <div @click="showPage('config')">            
            <img src="/img/icon-sensor.svg"/>
            <div>Add sensor</div>            
        </div>        
        <div @click="showPage('profile')">
            <img src="/img/icon-location.svg"/>
            <div>Set Position</div>
        </div>        
    </div>    

</template>        
