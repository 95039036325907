<script>

    import Languages from '@/utils/Languages'
    import Events from '@/utils/Events'    
    import GlobalMixin from '@/utils/Global.mixin'   

    export default {
        props:['pageId'],
        mixins: [GlobalMixin],        
        data: function() {
            return {
                loading:false,
                strings:Languages.appStrings(),
            }
        },
        methods: {
            loginClick() {
                this.loading = true;
                this.$emit('loginClick');
            }
        },  
        created() {
            Events.register("loginEnded", () => {     
                console.log("LOGIN PROCESS ENDED");                   
                this.loading = false;
            });   
        },
        mounted() {
            var appContentElement =  document.getElementById("appContent"); 
            var loginButton = document.getElementById("loginButtonWrapper");
            var loginButtonText = document.getElementById("loginButtonText");
            var loginWrapper = document.getElementById("page-login-wrapper");            
            loginButton.addEventListener("mouseout", function() { 
                loginButton.style = "";
                loginButtonText.style = "";
            });
            loginButton.addEventListener("mousemove", function(event) { 
                var rect = loginWrapper.getBoundingClientRect();
                var halfW = ( rect.width / 2 );
                var halfH = ( this.clientHeight / 2 );
                var coorX = ( halfW - ( event.pageX - rect.left ) );
                var coorY = ( halfH - ( event.pageY - this.offsetTop ) );
                var degX  = ( ( coorY / halfH ) * 10 ) + 'deg'; // max. degree = 10
                var degY  = ( ( coorX / halfW ) * -10 ) + 'deg'; // max. degree = 10
                loginButton.style.transform = 'perspective( 372px ) translate3d( 0, -2px, 0 ) scale(1.1) rotateX('+ degX +') rotateY('+ degY +')';      
                loginButtonText.style.transform = 'perspective( 372px ) translate3d( 0, -5%, 0 ) rotateX('+ degX +') rotateY('+ degY +')';
            });         
            document.addEventListener("mousemove", function(e) {                
                if (!window.loggedIn) {  
                    var gradient = '-webkit-radial-gradient(' + e.pageX + 'px ' + e.pageY + 'px,farthest-side, rgba(245,245,245,1) 0%, rgba(245,245,245,0) 100%)';                                 
                    if (appContentElement) appContentElement.style.background = gradient;                                    
                }
            });            
        }   
    }
</script>

<template>
    <div id="page-login" class="page" :class="{ active: currentPage.id==pageId }">
        <div id="page-login-wrapper" class="padding">        
            <img class="header-image" src="/img/icon-gear.svg">        
            <h1>{{strings.login.title}}</h1>
            <p v-html="strings.login.text"></p>
            <div id="loginButtonWrapper" style="">
                <button id="loginButton" v-on:click="loginClick()" class="green" :class="{loading:loading}">
                    <div id="loginButtonText">{{strings.login.button}}</div>
                </button>
            </div>
        </div>
    </div>
</template>