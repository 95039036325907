
<template>
    <div class="select-buttons">
        <div v-for="item in items" class="item" :class="{active:modelValue==item.id, rows:rows}" :key="item.id" @click="clicked(item)">
            <div>
                <div class="name">{{item.name}}</div>
                <div v-if="rows" class="text">{{item.text}}</div>
            </div>
            <span class="icon" v-if="icons" :class="['icon-' + item.icon + '-icon']"></span>
        </div>   
    </div>     
</template>

<script>
    module.exports = {
        props: ['items','icons','rows','modelValue'],
        emits: ['update:modelValue'],
        methods: {
            clicked:function(item) {            
                //this.$emit('input', item.id);
                //this.$emit('clicked');
                this.$emit('update:modelValue', item.id);
            },
        },    
    };
</script>
