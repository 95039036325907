
//import $ from "jquery";
import Events from '@/utils/Events'


class Api {
    
    constructor() {
        this.apiUrl = "https://api.callisto.pinch.services"        
        this.token = localStorage.getItem("token");

        Events.register("token", (updatedToken) => {
            this.token = updatedToken;
        });          
    }

    /* Request using fetch */
    ajax(endPoint, type, eventData, successCallback) {        
        var url = this.apiUrl + endPoint;          
        var myHeaders = new Headers({
            'Content-Type': 'application/json; ; charset=utf-8',
            'Authorization': "Bearer " + this.token
        });
        fetch(url, {
            method: type,   
            timeout:60000,         
            headers: myHeaders                                                                 
        })
        .then(response => {
            return response.text()
        })
        .then((data) => {
            if (successCallback!="undefined") successCallback(data ? JSON.parse(data) : {})
        })
        .catch((error) => {
            console.error(error);
        })
    }  

    /* Request using jquery */
    // ajax(endPoint, type, eventData, successCallback, errorCallback) { 
    //     var url = this.apiUrl + endPoint;       
    //     console.log("ajax", url);
    //     $.ajax({
    //         url:  url,
    //         type: type,
    //         data: eventData?JSON.stringify(eventData):"",
    //         contentType: "application/json; charset=utf-8",                        
    //         timeout: 60000,
    //         beforeSend: function (request) {
    //             request.setRequestHeader("Authorization", "Bearer " + this.token);
    //         },                      
    //         success: function (data) {                
    //             if (typeof successCallback != "undefined") successCallback(data);
    //         },
    //         error: function (XMLHttpRequest, textStatus, errorThrown) {
    //             console.log("ERROR", XMLHttpRequest, textStatus, errorThrown);
    //             if (typeof errorCallback != "undefined") errorCallback(errorThrown);
    //         }
    //     });
    // }

    getCustomers(successCallback) {
        this.ajax("/platform/v1/customers?withChildren=true&page=0","GET","", function(response) {            
            if (typeof successCallback != "undefined") successCallback(response);
        });  
    }

    getBuses(depotId, pageIndex, successCallback) {
        console.log("getBuses","_" + depotId + "_");        
        var endPointUrl = "/mobility/v1/depots/" + depotId + "/buses?page=" + pageIndex;
        this.ajax(endPointUrl,"GET","", function(response) {            
            if (typeof successCallback != "undefined") successCallback(response);
        });          
    }

    getVehicle(vehicleId, successCallback) {                     
        var endPointUrl = "/mobility/v1/buses/" + vehicleId;
        console.log("getBus",endPointUrl);   
        this.ajax(endPointUrl,"GET","", function(response) {            
            if (typeof successCallback != "undefined") successCallback(response);
        });            
    }

    getSensors(vehicleId, successCallback) {                     
        var endPointUrl = "/mobility/v1/buses/" + vehicleId + "/sensors";
        console.log("getSensors",endPointUrl);   
        this.ajax(endPointUrl,"GET","", function(response) {            
            if (typeof successCallback != "undefined") successCallback(response);
        });            
    }    

    addBus(vehicleData, successCallback) {
        console.log("addBus",vehicleData);        
        var endPointUrl = "/mobility/v1/buses";
        this.ajax(endPointUrl,"POST",vehicleData, function(response) {            
            if (typeof successCallback != "undefined") successCallback(response);
        });          
    }    
}

export default new Api()
