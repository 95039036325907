<script>
    import EditMixin from '@/utils/Edit.mixin'    
    import Popover from '@/components/units/Popover.vue'
    import GlobalMixin from '@/utils/Global.mixin' 

    export default {
        mixins: [GlobalMixin,EditMixin],        
        data:function(){
            return {
                editSensor:{}
            }
        },
        watch: {                                               
            currentPopoverData:function(n) {                
                console.log("currentPopoverData CHANGED for editSensor",n);
                //this.editSensor = JSON.parse(JSON.stringify(this.currentPopoverData));
                this.editSensor = this.currentPopoverData;
                if (!this.editSensor.installation) {
                    this.editSensor.installation = this.defaultSensorSettings["bus"];
                }
            },
        },           
        components:{
            Popover
        },
        computed: {        
            mrssi:function() {                
                return this.powerMrssi[this.installation.transmitPower];
            },
            uuidIndex: {                
                get() {                    
                    return this.beaconIdentifier.uuid?this.beaconIdentifier.uuid.slice(-2):"";                    
                },
                set(value) {
                    this.beaconIdentifier.uuid = this.beaconBaseUUID + value;
                }                
            },
            installation:function() {
                return this.editSensor && this.editSensor.installation ? this.editSensor.installation : {};   
            },
            eddystoneIdentifier:function() {
                return this.editSensor && this.editSensor.identifiers ? this.editSensor.identifiers.find(identifier => identifier.identifierType==2) || {} : {};          
            },
            beaconIdentifier:function() {
                return this.editSensor && this.editSensor.identifiers ? this.editSensor.identifiers.find(identifier => identifier.identifierType==1) || {} : {};                   
            },
            serialNumber() {
                if (this.editSensor && this.editSensor.identifiers) {
                    var eddystoneId = this.editSensor.identifiers.find(identifier => identifier.identifierType==2);                    
                    if (eddystoneId) return eddystoneId.identifier.replace("5dc33487f02e477d4058","");
                }                
                return "";
            }
        },
        methods: {
            closePopover() {
                this.$emit('close');
            },
            saveSensor() {
                console.log(this.beaconIdentifier);
            }
        },             
        created() {
            console.log("PopoverEditSensor CREATED", this.editSensor);
            window.edit = this.editSensor
        }        
    }
</script>

<template>

    <popover id="editSensor" :title="!serialNumber?'Configure new Beacon':'Change Settings'">

        <div class="cols">
            <div class="field" v-if="serialNumber">
                <h3>Serial Number</h3>                
                <input type="text" disabled v-model="serialNumber">
            </div>  
            <div class="field" v-if="!serialNumber">
                <h3>Serial Number</h3>     
                <button class="mt-0 green mr-05" @click="configureBeacon(sensor)">
                    <img src="/img/icon-qr.svg">
                    Scan QR
                </button>          
            </div>  
            <div class="field">
                <h3>UUID</h3>                                
                <div class="select-wrapper">
                    <select v-model="uuidIndex">
                        <option v-for="(name,index) in uuidOptions" v-bind:value="name" :key="index">
                            {{name}}
                        </option>
                    </select>                
                </div>
            </div>             
        </div>

        <div class="cols">
            <div class="field">
                <h3>Major</h3>                
                <input type="text" v-model="beaconIdentifier.major">
            </div>  
            <div class="field">
                <h3>Minor</h3>                
                <input type="text" v-model="beaconIdentifier.minor">
            </div>             
        </div>        

        <div class="cols">
            <div class="field">
                <h3>Transmit Power</h3>                   
                <div class="select-wrapper">
                    <select v-model="installation.transmitPower">
                        <option v-for="(power,index) in powerOptions" v-bind:value="index" :key="index">
                            {{power}}
                        </option>
                    </select>          
                </div>
            </div>  
            <div class="field">
                <h3>Transmit Interval</h3>                   
                <div class="select-wrapper">
                    <select v-model="installation.advertisingInterval">
                        <option v-for="(interval,index) in transmitIntervals" v-bind:value="index" :key="index">
                            {{interval}}
                        </option>
                    </select>
                </div>
            </div>
        </div> 

        <div class="cols">            
            <div class="field">
                <h3>MRSSI</h3>                   
                <input type="text" disabled v-model="mrssi">
            </div>
            <div class="field">
                <h3>Telemetry</h3>                   
                <div class="select-wrapper">
                    <select v-model="installation.telemetryInterval">
                        <option v-for="(interval,index) in telemetryIntervals" v-bind:value="index" :key="index">
                            {{interval}}
                        </option>
                    </select>    
                </div>
            </div>              
        </div> 

        <!-- <div class="cols">
            <input type="checkbox" id="eddystoneEnabled" name="eddystoneEnabled" v-model="currentBeaconData.eddystoneEnabled">
        </div> -->


        <div class="cols uneven">       
            <button @click="closePopover()" class="outline">Cancel</button>                                    
            <button @click="saveSensor()" class="green">Configure</button>                                     
        </div>           

    </popover>


</template>