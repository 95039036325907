<script>
    //import BeaconConfig from '@/utils/BeaconConfig'
    import Api from '@/utils/Api'
    import SelectItems from '@/components/inputs/SelectItems.vue'
    import Events from '@/utils/Events'
    import GlobalMixin from '@/utils/Global.mixin'       

    export default {
        props:['pageId'],
        mixins: [GlobalMixin],        
        data:function() {
            return {
                buttonIndex:0,
                customers:[],        
                currentDepot:{},
                currentCustomer:{},
                dataBuses:{},    
                loading:false
            }
        },        
        watch: {                                               
            currentCustomer:function() {                
                this.currentDepot = {};
                this.dataBuses = {};
            },
            currentDepot:function(n) {            
                if (Object.keys(n).length) {
                    this.dataBuses = {};
                    this.getBuses();
                } else {
                    this.dataBuses={};
                }
            }                          
        },          
        components:{
            SelectItems
        },
        methods: {
            getStatus:function(item) {
                if (item.otherCount>0) return "red";
                else if (item.pendingCount>0) return "yellow";
                else if (item.readyCount>0) return "green";
                return "gray";
            },
            getBuses:function(callback) {
                console.log("GET BUSES METHOD");                
                var self = this;
                var pageIndex = 0;
                this.loading = true;
                if (this.dataBuses.pageIndex) pageIndex = this.dataBuses.pageIndex;
                Api.getBuses(this.currentDepot.id, pageIndex, function(busData){
                    console.log("BUSES", busData);
                    self.dataBuses = busData;
                    self.loading = false;
                    if (typeof callback!="undefined") callback();
                });
            },              
            getCustomers:function() {
                var self = this;
                console.log("PageInstall getCustomers"); 
                Api.getCustomers(function(response){
                    console.log("CUSTOMERS", response, response.items);
                    self.customers = response.items;
                    if (response.items.length==1) self.currentCustomer = self.customers[0];
                });
            },
            nextBusPage:function(index) {                
                var currentIndex = this.dataBuses.pageIndex;
                this.dataBuses.pageIndex += index;            
                if (this.dataBuses.pageIndex > this.dataBuses.totalPages) this.dataBuses.pageIndex = this.dataBuses.totalPages;
                if (this.dataBuses.pageIndex < 1) this.dataBuses.pageIndex = 1;

                if (currentIndex!=this.dataBuses.pageIndex) {
                    this.buttonIndex = index;        
                    var self = this;                    
                    this.getBuses(function() {
                        clearTimeout(self.busTimeout);
                        self.busTimeout = setTimeout(function(){                            
                            self.buttonIndex = 0;                                
                        },100);                 
                    });
                }
            },
            showVehicle:function(vehicle) {
                console.log(vehicle);                  
                this.showPage("vehicle", vehicle);  
            }           
        },
        created() {           
            console.log("PageInstall CREATED");            
            Events.register("init", () => {        
                this.getCustomers();
            });                     
            this.getCustomers();                                                     
        }                       
    }
</script>

<template>

    <div :id="pageId" class="page"  :class="{ active: currentPage.id==pageId, previous:previousPage.id==pageId, root:navigation.pages[0] && navigation.pages[0].id==pageId}">

        <h2>Select Depot</h2>

        <div class="field" v-show="customers.length>1">
            <h3>Customer</h3>                    
            <select-items :items="customers" name-property="name" id-property="id" v-model="currentCustomer" placeholder="Select Customer"></select-items>
        </div>

        <div class="field" v-show="Object.keys(currentCustomer).length">
            <h3>Depots</h3>
            <select-items :items="currentCustomer.locations" name-property="name" id-property="id" v-model="currentDepot" placeholder="Select Depot"></select-items>
        </div>

        <div class="mt-2" v-if="dataBuses.totalCount"> 
            <div class="header-option">
                <h2>Depot Buses</h2>
                <div @click="showPopover('filter')" class="clickpadding">
                    <img class="icon" src="/img/icon-filter.svg">
                    <span class="link">Filter</span>
                </div>
            </div>
            <div class="input-wrapper search">
                <input type="text" placeholder="Search query" data-lpignore="true">
            </div>

            <div v-if="dataBuses.totalPages>1" class="select-buttons mt-1p mb-1 reactive">
                <div class="item" @click="nextBusPage(-1)" :class="{active:buttonIndex==-1, disabled:dataBuses.pageIndex==1}">Previous</div>
                <div class="item strong">{{dataBuses.pageIndex}} of {{dataBuses.totalPages}}</div>
                <div class="item" @click="nextBusPage(1)" :class="{active:buttonIndex==1, disabled:dataBuses.pageIndex==dataBuses.totalPages}">Next</div>
            </div>

            <div v-if="!loading">
                <div class="list tappable mt-1 mb-1">
                    <div class="item" v-for="item in dataBuses.items" :key="item.id" @click="showVehicle(item)">
                        <div class="status" :class="getStatus(item)"></div>
                        <div class="description">
                            <div class="title">{{item.name}}</div>
                            <div class="smalltext">{{item.manufacturer}} {{item.registrationNumber}}</div>
                        </div>      
                      <div class="extra">                            
                            <div class="title">{{item.readyCount}}</div>
                            <div class="smalltext">Done</div>                            
                        </div>                                 
                        <div class="extra mr-0">                            
                            <div class="title">{{item.pendingCount}}</div>
                            <div class="smalltext">Install</div>                            
                        </div>                                         
                        <div class="icon arrow"></div>
                    </div>                                
                </div>
                <button @click="showPopover('editVehicle')">Add bus</button>
            </div>       
    
        </div>

        <div class="page-loader" v-if="loading && !navigation.animating">
            <img src="/img/icon-loader.svg">    
        </div>             

    </div>

</template>    