<template>

  <div id="appContent" class="content" :class="{activeDialog:currentDialog!='', fullscreen:isApp, ready:ready, hasToolbar:showToolbar, loggedIn:loggedIn}">

    <page-login pageId="login" @loginClick="loginClick()">
    </page-login>

    <page-install pageId="install">
    </page-install> 

    <page-vehicle pageId="vehicle">
    </page-vehicle>

    <page-manual-config pageId="config">
    </page-manual-config> 

    <page-edit-sensor pageId="editSensor">
    </page-edit-sensor>     

    <div id="profile" class="page" :class="{ active: currentPage.id=='profile' }">            
        <button @click="logoutClick()">
            Logout
        </button>     
    </div>

    <svg id="line_loader"><line x1="100%" y1="0%" x2="0%" y2="0%"></line></svg>

    <div class="statusbar" :class="{ active: currentPage.statusbarVisible }">
        <div class="back" @click="popPage()" :class="{active:navigation.pages.length>1}">
            <img class="icon valigntop" src="/img/icon-back.svg">
        </div>
        <div class="title">{{currentPage.title}}</div>
        <div class="menu" @click="menuVisible=!menuVisible">
            <img class="icon valigntop" src="/img/icon-hamburger.svg">
        </div>        
    </div>    

    <div class="toolbar" :class="{ active: currentPage.toolbarVisible }">
        <div v-bind:class="{ active: currentPage=='config' }" @click="showPage('config')">
            <span class="icon icon-recruit-icon"></span>
            <div>Config</div>            
        </div>
        <div v-bind:class="{ active: currentPage=='install' }" @click="showPage('install')">
            <span class="icon icon-stats-icon"></span>         
            <div>Locations</div>            
        </div>
        <div v-bind:class="{ active: currentPage=='profile' }" @click="showPage('profile')">
            <span class="icon icon-profile-icon"></span>
            <div>Profile</div>
        </div>        
    </div>


    <div class="menupage-wrapper" :class="{ active: menuVisible, hide:hideMenu && menuVisible  }">
        <div class="menupage-background" @click="closeMenu()"></div>
        <div class="menupage">
            <div class="header-option px-1">
                <h1>Menu</h1> 
                <div @click="closeMenu()" class="clickpadding"><img src="/img/icon-close.svg" class="icon"></div>
            </div>                
            <div class="menulist mt-1 mb-1">
                <template v-for="page in pages" :key="page.id">                
                    <div class="item"  v-if="page.inMenu" v-bind:class="{ active: currentPage==page.id }" @click="setRootPage(page.id)" >                        
                        <div class="description">
                            <div class="title">{{page.title}}</div>
                            <div class="smalltext">{{page.description}}</div>
                        </div>                                                 
                        <div class="icon arrow"></div>
                    </div>
                </template>
            </div>
        </div>
    </div>        


    <div class="dialog-wrapper" :class="{ active: currentDialog=='start', hide:hideDialog }">
        <div class="dialog-background" @click="closeDialog()">
        </div>
        <div class="dialog">
            <h2>Title</h2>
            <p>Text</p>
            <div class="buttons">
                <button class="outline">Cancel</button>
                <button>Start</button>
            </div>                
        </div>
    </div>


    <popover-filter>
    </popover-filter>

    <popover-edit-vehicle>
    </popover-edit-vehicle>

    <popover-edit-sensor>
    </popover-edit-sensor>
    
    <div class="toast-wrapper" :class="{ active: toastVisible, hide:hideToast  }" @click="closeToast()">
        <div class="toast-background">
        </div>
        <div class="toast">
            <h2>{{toast.title}}</h2>
            <p v-html="toast.message"></p>                
        </div>
    </div>   

  </div>
  
</template>

<script>


  import PageManualConfig from '@/components/pages/PageManualConfig'
  import PageInstall from '@/components/pages/PageInstall'
  import PageLogin from '@/components/pages/PageLogin'
  import PageVehicle from '@/components/pages/PageVehicle'
  import PageEditSensor from '@/components/pages/PageEditSensor'

  import PopoverEditVehicle from '@/components/popovers/PopoverEditVehicle'
  import PopoverEditSensor from '@/components/popovers/PopoverEditSensor'
  import PopoverFilter from '@/components/popovers/PopoverFilter'

  import Events from '@/utils/Events'
  import Utils from '@/utils/Utils'
    
  import Authorization from './utils/Authorization'  
  import AppMixin from './utils/App.mixin'


  export default {
    name: 'App',
    components: {      
      PageVehicle,PageLogin,PageInstall,PageManualConfig,PageEditSensor,PopoverEditVehicle,PopoverEditSensor,PopoverFilter        
    },
    mixins: [AppMixin,Authorization],        
    created() {                        
        console.log("APP Created");     
    },              
    mounted(){      
      Utils.setMounted();
      Events.register("alert", (data) => {
        this.name = data
      });
      Events.register("appReady", () => {        
        this.appReady();
      });      
      window.app = this;
    }
  }
</script>
