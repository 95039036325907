import moment from 'moment'

export default {     
    data:{    
        currentPopover:'',
        currentPopoverData:{},        
        currentDialog:'',         
        lastPage:'',       
        hideDialog:false,
        hideToast:false,
        hidePopover:false,
        toastVisible:false,
        navigation: {
            animating:false,         
            pages:[]
        },
        pages: {
            "profile":{
                id:"profile",                
                title:"Profile",
                description:"View and edit profile",                
                icon:"icon-stats-icon",
                inMenu:true,
                statusbarVisible:true,
                toolbarVisible:false
            },
            "config": {
                id:"config",                
                title:"Manual Config",
                description:"Configure sensors manually",                
                icon:"icon-profile-icon",
                inMenu:true,
                statusbarVisible:true,
                toolbarVisible:false
            },       
            "login": {
                id:"login",                
                title:"Login",                                          
                inMenu:false,
                statusbarVisible:false,
                toolbarVisible:false
            },
            "install": {
                id:"install",                
                title:"Installation",
                description:"Configure vehicles",                
                icon:"icon-recruit-icon",
                inMenu:true,
                statusbarVisible:true,
                toolbarVisible:false
            },            
            "vehicle": {
                id:"vehicle",                
                title:"Vehicle Installation",
                inMenu:false,
                statusbarVisible:true,
                toolbarVisible:false
            },            
            "editSensor": {
                id:"editSensor",                
                title:"Configure Sensor",
                inMenu:false,
                statusbarVisible:true,
                toolbarVisible:false
            },                                           
        }        
    },    
    computed: {
        currentPage:function() {            
            if (this.navigation.pages.length>0) {
                return this.navigation.pages[this.navigation.pages.length-1];
            }
            return {id:""};
        },
        previousPage:function() {
            if (this.navigation.pages.length>1) {
                return this.navigation.pages[this.navigation.pages.length-2];
            }
            return {id:""};
        }
    },
    methods: {
        formatDate: function (date) {
            return moment(date).format("DD.MM.YYYY")
        },      
        setRootPage:function(pageId, pageData) {
            var page = this.pages[pageId];
            if (page) {
                page.pageData = pageData;
                this.navigation.pages = [page];                   
            } else {
                console.error("UNKNOWN PageId", pageId);
            }
            this.closeMenu();
        },        
        replacePage:function(pageId, pageData) {
            var page = this.pages[pageId];
            if (page) {
                page.pageData = pageData;
                this.navigation.pages[this.navigation.pages.length>1?this.navigation.pages.length-1:0] = page;
            } else {
                console.error("UNKNOWN PageId", pageId);
            }
            this.closeMenu();
        },
        showPage:function(pageId, pageData) {          
            console.log("GLOBAL showPage", pageId, pageData);  
            var page = this.pages[pageId];
            if (page) {
                page.pageData = pageData;
                this.navigation.pages.push(page); 
                this.setNavigationAnimating();
            } else {
                console.error("UNKNOWN PageId", pageId);
            }            
            this.closeMenu();                                               
        }, 
        popPage:function() {                
            this.navigation.pages.pop();
            this.setNavigationAnimating();        
        },  
        setNavigationAnimating:function() {
            this.navigation.animating = true;
            var self = this;
            setTimeout(function() {
                self.navigation.animating = false;
            },500);   
        },
        showDialog:function(dialog) {
            this.currentDialog = dialog;
        },   
        closeMenu:function() {
            this.hideMenu = true;  
            var self = this;
            setTimeout(function(){
                self.menuVisible = false;
                self.hideMenu = false;
            },700);   
        },        
        closeDialog:function() {                    
            this.hideDialog = true;
            var self = this;
            setTimeout(function(){
                self.currentDialog = '';
                self.hideDialog = false;
            },600);
        },            
        closeToast:function() {                    
            this.hideToast = true;
            var self = this;
            setTimeout(function(){
                self.toastVisible = false;
                self.hideToast = false;     
            },600);
        },                    
        showToast:function(toast, timeout=3000) {        
            //$(".page").animate({ scrollTop: 0 }, "slow");            
            this.toastVisible = true;  
            if (typeof toast=="object") this.toast = toast;
            else this.toast = this.strings.alerts[toast];
            var self = this;
            if (timeout>0) {
                setTimeout(function() {
                    self.closeToast();
                },timeout);
            }
        },
        showPopover:function(id, popoverData) {
            this.hidePopover = false;
            this.currentPopover = id;
            if (popoverData) this.currentPopoverData = popoverData;
        },       
        closePopover:function() {
            console.log("closePopover");
            this.hidePopover = true;
            var self = this;
            setTimeout(function(){
                self.currentPopover = '';
                self.hidePopover = false;
                self.currentPopoverData = {};
            },600);            
        },

    }
}