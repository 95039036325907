
import Languages from '@/utils/Languages'
import Events from '@/utils/Events'
import GlobalMixin from '@/utils/Global.mixin'    

export default { 

    mixins: [GlobalMixin],
    data:{    
        dateNow: new Date(),  
        ready:false,
        strings:Languages.appStrings(),
        isApp:window.webkit && window.webkit.messageHandlers,
        isFullscreen:false,                
        loggedIn:false,
        userData:null,
        buttonIndex:0,     
        busTimeout:null,
        toast:{                    
            title:"",
            message:""
        },                                    
        showToolbar:false,                
        profile: {
            project:'',
            crewId:'',
            firstName:'',
            lastName:''
        },
        menuVisible:false,
        hideMenu:false,        
    },  
    methods: {
        appReady:function() {     
            console.log("ROOT - APP READY");
            var self = this;                               
            this.loginSilent(function(succeeded) {
                self.init(succeeded);
            });                             
        },     
        loginClick:function() {            
            var self = this;
            this.loginInteractive(function() {                                
                self.init(true);    
            });       
        },      
        logoutClick:function() {
            var self = this;
            this.logout(function() {
                self.currentPage = "login";
                self.showToolbar = false;
            });
        },                               
        init:function(loggedIn) {            
            if (loggedIn) {                                
                console.log("TRIGGER INIT", loggedIn);                        
                Events.trigger("init");                                   
                this.loggedIn = loggedIn;  
                window.loggedIn = loggedIn;   
                this.setRootPage("install")           
            } else {                
                this.showToolbar = false;
                this.setRootPage("login");
            }
            this.ready = true;  
        },      
        doNothing:function() {
            console.log("VOID");
        },
        stopTheEvent: (event) => event.stopPropagation()                 
    },
    created() {                        
        // Events.register("showPopover", (info) => { 
        //     console.log("EVENT showPopover", info);   
        //     this.currentPopoverData = info.data;    
        //     this.showPopover(info.id);
        // });                       
        // Events.register("showPage", (info) => {  
        //     console.log("EVENT showPage", info);     
        //     this.currentPageData = info.data;    
        //     this.showPage(info.id);
        // });                     
    },  
    mounted() {
        console.log();
        // window["$"](document).mousemove(function (e) {
        //     console.log(e);
        // });
    }
}

