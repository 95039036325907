<script>
    import EditMixin from '@/utils/Edit.mixin'        
    import GlobalMixin from '@/utils/Global.mixin' 

    export default {
        mixins: [GlobalMixin,EditMixin],
        props:['pageId'],        
        data:function(){
            return {
                sensorData:{}
            }
        },
        watch: {           
            'currentPage': function (){                
                if (this.currentPage.id==this.pageId) this.init();    
            },                                               
        },                    
        components:{            
        },
        computed: {        
            mrssi:function() {                
                return this.powerMrssi[this.installation.transmitPower];
            },
            uuidIndex: {                
                get() {                    
                    return this.beaconIdentifier.uuid?this.beaconIdentifier.uuid.slice(-2):"";                    
                },
                set(value) {
                    this.beaconIdentifier.uuid = this.beaconBaseUUID + value;
                }                
            },
            installation:function() {
                return this.sensorData && this.sensorData.installation ? this.sensorData.installation : {};   
            },
            eddystoneIdentifier:function() {
                return this.sensorData && this.sensorData.identifiers ? this.sensorData.identifiers.find(identifier => identifier.identifierType==2) || {} : {};          
            },
            beaconIdentifier:function() {                
                return this.sensorData && this.sensorData.identifiers ? this.sensorData.identifiers.find(identifier => identifier.identifierType==1) || {} : {};                   
            },
            serialNumber() {
                if (this.sensorData && this.sensorData.identifiers) {
                    var eddystoneId = this.sensorData.identifiers.find(identifier => identifier.identifierType==2);                    
                    if (eddystoneId) return eddystoneId.identifier.replace("5dc33487f02e477d4058","");
                }                
                return "";
            }
        },
        methods: {
            init:function() {                
                this.sensorData = this.currentPage.pageData;
                console.log("INIT EDIR SENSOR PAGE",this.sensorData);
                if (!this.sensorData.installation) {
                    this.sensorData.installation = this.defaultSensorSettings["bus"];
                }
            }
        },                        
        created() {
                        
        }        
    }
</script>

<template>

    <div :id="pageId" class="page" :class="{ active: currentPage.id==pageId, previous:previousPage.id==pageId }">

        <div class="main">

        <h2>Identification</h2>

        <div class="cols">
            <div class="field" v-if="serialNumber">
                <h3>Serial Number</h3>                
                <input type="text" disabled v-model="serialNumber">
            </div>  
            <div class="field" v-if="!serialNumber">
                <h3>Serial Number</h3>     
                <button class="mt-0 green mr-05" @click="configureBeacon(sensor)">
                    <img src="/img/icon-qr.svg">
                    Scan QR
                </button>          
            </div>  
            <div class="field">
                <h3>UUID</h3>                                
                <div class="select-wrapper">
                    <select v-model="uuidIndex">
                        <option v-for="(name,index) in uuidOptions" v-bind:value="name" :key="index">
                            {{name}}
                        </option>
                    </select>                
                </div>
            </div>             
        </div>

        <div class="cols">
            <div class="field">
                <h3>Major</h3>                
                <input type="text" v-model="beaconIdentifier.major">
            </div>  
            <div class="field">
                <h3>Minor</h3>                
                <input type="text" v-model="beaconIdentifier.minor">
            </div>             
        </div>        


        <h2 class="mt-2">Signal Settings</h2>

        <div class="cols">
            <div class="field">
                <h3>Transmit Power</h3>                   
                <div class="select-wrapper">
                    <select v-model="installation.transmitPower">
                        <option v-for="(power,index) in powerOptions" v-bind:value="index" :key="index">
                            {{power}}
                        </option>
                    </select>          
                </div>
            </div>  
            <div class="field">
                <h3>Transmit Interval</h3>                   
                <div class="select-wrapper">
                    <select v-model="installation.advertisingInterval">
                        <option v-for="(interval,index) in transmitIntervals" v-bind:value="index" :key="index">
                            {{interval}}
                        </option>
                    </select>
                </div>
            </div>
        </div> 

        <div class="cols">            
            <div class="field">
                <h3>MRSSI</h3>                   
                <input type="text" disabled v-model="mrssi">
            </div>
            <div class="field">
                <h3>Telemetry</h3>                   
                <div class="select-wrapper">
                    <select v-model="installation.telemetryInterval">
                        <option v-for="(interval,index) in telemetryIntervals" v-bind:value="index" :key="index">
                            {{interval}}
                        </option>
                    </select>    
                </div>
            </div>              
        </div> 

        <!-- <div class="cols">
            <input type="checkbox" id="eddystoneEnabled" name="eddystoneEnabled" v-model="currentBeaconData.eddystoneEnabled">
        </div> -->

        </div>

        <div class="footer">    

            <button v-if="sensorData.state==1" class="mt-0 mr-05" @click="scanSerial()" :class="{green:!serialNumber}">
                <img src="/img/icon-qr.svg">
                Scan QR Serialnumber 
            </button>      
                                
            <button class="green" v-if="serialNumber">Start Configuration</button>
   
        </div>

    </div>


</template>