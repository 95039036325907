
<template>    
    <div class="select-wrapper" :class="{disabled:disabled}">                                
        <select :disabled="disabled" class="select" v-model="value" :class="{selected:value}">
            <option value="">{{placeholder}}</option>
            <option v-bind:value="item[idProperty]" v-for="(item,index) in items" :key="index">{{ item[nameProperty] }}</option>                
        </select>   
    </div>    
</template>

<script>
    module.exports = {
        props: ['modelValue','items','disabled','placeholder','nameProperty','idProperty'],
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    var valueHasObject = Object.prototype.hasOwnProperty.call(this.modelValue, this.idProperty);
                    if (this.modelValue && valueHasObject) return this.modelValue[this.idProperty];
                    else return "";
                },
                set(value) {                    
                    var selectedObject = this.items.find(x => x[this.idProperty] === value);                                   
                    this.$emit('update:modelValue', selectedObject?selectedObject:"");
                    console.log("emit",selectedObject?selectedObject:"");     
                }
            }        
        }            
    };
</script>
