
export default { 
    
    data() {
        return {
            vehicleTypes:[
                { id:"bus", name:"Bus", icon:"bus"},
                { id:"tram", name:"Tram", icon:"tram"},
                { id:"train", name:"Train", icon:"train"},
                { id:"boat", name:"Boat", icon:"boat"}
            ],
            busTypes:[
                { id:"bus", name:"Standard", icon:"bus"},
                { id:"bus-tandem", name:"Articulated", icon:"bus-tandem"},
            ],
            defaultSensorSettings: {
                bus: {                                  
                    sensorType: 2,
                    sensorState: 1,    
                    telemetryInterval: 2,                
                    transmitPower: 7,
                    advertisingInterval: 2                                    
                }
            }
        }    
    }
}