<script>
    import GlobalMixin from '@/utils/Global.mixin' 

    export default {
        mixins: [GlobalMixin],
        data() {
            return {
            }
        },
        props: ['id','title','icon'],         
    }
</script>

<template>

    <div class="popover-wrapper" :class="{ active: currentPopover==id, hide:hidePopover }">            
        <div class="popover-background" @click="closePopover()">
        </div>
        <div class="popover">
            <div class="header-option">                    
                <h2>                        
                    <img v-if="icon" class="icon" :src="'/img/icon-' + icon + '.svg'" /> 
                    {{title}}      
                </h2>
                <div class="clickpadding" @click="closePopover()">
                    <img class="icon" src="/img/icon-close.svg">                        
                </div>
            </div>            
            <slot/>
        </div>                         
    </div>

</template>