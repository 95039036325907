
<script>
    import BeaconConfig from '@/utils/BeaconConfig'
    import GlobalMixin from '@/utils/Global.mixin'   

    export default {       
        props:['pageId'], 
        mixins: [GlobalMixin,BeaconConfig],
        data() {
            return {
            }
        }        
    }
</script>

<template>

    <div :id="pageId" class="page" :class="{ active: currentPage.id==pageId, previous:previousPage.id==pageId }">
  
      <button @click="toggleRangeBeacons()">{{beaconRanging?"Stop":"Start"}} Range Beacons</button>
      
      <div v-if="!beaconRanging">                       
          <button @click="qrscanBeaconSerial()">QR Scan Serial</button>
          <button @click="getBeaconConfigByScan()">Get Beacon By QR Scan</button>
          <button v-if="currentBeaconSerial" @click="getBeaconConfigBySerial()">Get Beacon By Serial</button>
          <button @click="resetBeacon()">Reset Beacon</button>
          <button v-if="currentBeaconData" @click="configBeacon()">Config Beacon</button>
      </div>
      
      <div class="table" v-if="beaconRanging">
          <div class="row" v-for="beacon in rangedBeacons" :key="beacon.id">
              <div class="col">{{beacon.count}}</div>
              <div class="col">{{beacon.uuid.slice(-2)}}</div>
              <div class="col">{{beacon.major}}</div>
              <div class="col">{{beacon.minor}}</div>
              <div class="col">{{beacon.rssi}}</div>
              <div class="col">{{beacon.accuracy}}</div>
          </div>
      </div>
      
      <div class="box" v-if="currentBeaconSerial && !beaconRanging">
          <div><strong>Beacon Serial</strong><br/></div>
          <div>{{currentBeaconSerial}}</div>
      </div>
                  
      <div class="box outline" v-if="beaconSearching && !beaconRanging">
          <div><strong>Ranging for {{currentBeaconSerial}}</strong></div>
          <div v-if="rangedBeacon.serialNumber">Detected: {{rangedBeacon.serialNumber}}</div>
      </div>
                  
      <div class="table twocols" v-if="currentBeaconData && !configLog">
          <div class="row">
              <div class="col">Serial</div>
              <div class="col"><input type="text" v-model="currentBeaconData.serialNumber"></div>
          </div>
          <div class="row">
              <div class="col">UUID</div>
              <div class="col" v-if="currentBeaconData.uuid_short">
                  <input type="text" class="index" readonly v-bind:value="currentBeaconData.uuid_short">
                  <select v-model="currentBeaconData.uuid_short" @change="forceUpdate()">
                      <option v-for="(name,index) in uuidOptions" v-bind:value="name" :key="index">
                          {{name}}
                      </option>
                  </select>
              </div>
          </div>
          <div class="row">
              <div class="col">Major</div>
              <div class="col"><input type="text" v-model="currentBeaconData.major"></div>
          </div>
          <div class="row">
              <div class="col">Minor</div>
              <div class="col"><input type="text" v-model="currentBeaconData.minor"></div>
          </div>
          <div class="row">
              <div class="col">TransmitPower</div>
              <div class="col">
                  <input type="text" class="index" readonly v-model="currentBeaconData.broadcastTransmitPower">
                  <select v-model="currentBeaconData.broadcastTransmitPower">
                      <option v-for="(power,index) in powerOptions" v-bind:value="index" :key="index">
                          {{power}}
                      </option>
                  </select>
              </div>
          </div>
          <div class="row">
              <div class="col">MRSSI</div>
              <div class="col"><input type="text" readonly v-model="currentBeaconData.mrssi"></div>
          </div>
          <div class="row">
              <div class="col">Interval</div>
              <div class="col">
                  <input type="text" class="index" readonly v-model="currentBeaconData.broadcastInterval">
                  <select v-model="currentBeaconData.broadcastInterval">
                      <option v-for="(interval,index) in transmitIntervals" v-bind:value="index" :key="index">
                          {{interval}}ms
                      </option>
                  </select>
              </div>
          </div>
          <div class="row">
              <div class="col">TML Interval</div>
              <div class="col">
                  <input type="text" class="index" readonly v-model="currentBeaconData.eddystoneTLMInterval">
                  <select v-model="currentBeaconData.eddystoneTLMInterval">
                      <option v-for="(interval,index) in telemetryIntervals" v-bind:value="index" :key="index">
                          {{interval}}
                      </option>
                  </select>
              </div>
          </div>
          <div class="row">
              <div class="col"><label for="eddystoneEnabled">EddystoneEnabled</label></div>
              <div class="col">
                  <input type="checkbox" id="eddystoneEnabled" name="eddystoneEnabled" v-model="currentBeaconData.eddystoneEnabled">
              </div>
          </div>
          <div class="row">
              <div class="col"><label for="eddystoneTLMEnabled">TLMEnabled</label></div>
              <div class="col">
                  <input type="checkbox" id="eddystoneTLMEnabled" name="eddystoneTLMEnabled" v-model="currentBeaconData.eddystoneTLMEnabled">
              </div>
          </div>
          <div class="footer">
              <div class="row">
                  <div class="col">BatteryLevel</div>
                  <div class="col">{{Math.round(currentBeaconData.batteryLevel*100)}}%</div>
              </div>
              <div class="row">
                  <div class="col">HardwareModelName</div>
                  <div class="col">{{currentBeaconData.hardwareModelName}}</div>
              </div>
              <div class="row">
                  <div class="col">FirmwareVersion</div>
                  <div class="col">{{currentBeaconData.firmwareVersion}}</div>
              </div>
              <div class="row">
                  <div class="col">Temperature</div>
                  <div class="col">{{currentBeaconData.temperature}}</div>
              </div>
              <div class="row">
                  <div class="col">Accuracy</div>
                  <div class="col">{{currentBeaconData.accuracy}}</div>
              </div>
          </div>
      </div>
      
      <textarea v-if="configLog" v-model="configLog" style="width:100%; height:300px">
      </textarea>

  </div>    

</template>