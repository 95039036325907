class Events {
    
    constructor() {
        this.events = {}
    }

    register(event, callback) {
        this.events[event] = callback
    }

    trigger(event, data) {

        if (this.events[event]) {
            this.events[event](data || {})
        }
    }
}

export default new Events()