<script>
    import Api from '@/utils/Api'
    import BeaconConfig from '@/utils/BeaconConfig'
    import EditMixin from '@/utils/Edit.mixin'
    import SelectButtons from '@/components/inputs/SelectButtons.vue'      
    import Popover from '@/components/units/Popover.vue'
    import GlobalMixin from '@/utils/Global.mixin' 

    export default {
        mixins: [GlobalMixin,EditMixin],         
        components:{
            SelectButtons,Popover
        },
        watch: {                                               
            currentPopoverData:function(n) {                
                console.log("currentPopoverData CHANGED",n);
                this.editVehicle = JSON.parse(JSON.stringify(this.currentPopoverData));
                this.editVehicle.type = "bus";
                this.editVehicle.subType = "bus";
            },
        },         
        data:function() {
            return {
                editVehicle:{
                    id:"bus",
                    type:"bus"            
                }    
            }
        },         
        methods: {
            saveVehicle:function() {
                var defaultSensors = BeaconConfig.getDefaultConfigSettings("bus");

                //if (this.editVehicle.id!="") 
                var vehicleData = {                    
                    "depotId": this.currentDepot.id,
                    "internalIdentifier": this.editVehicle.internalIdentifier,
                    "vin": "00000000000000000",
                    "registrationNumber": this.editVehicle.registrationNumber,
                    "manufacturer": this.editVehicle.manufacturer,
                    "model": this.editVehicle.model,
                    "description": "",
                    "sensors": defaultSensors
                }    
                var self = this;
                Api.addBus(vehicleData, function(responseData) {
                    console.log("addBus", responseData);
                    self.closePopover();
                    self.getBuses();
                    setTimeout(function() { self.editVehicle = { id:"bus", type:"bus" },500 });
                });
            }
        },             
        created() {
            console.log("PopoverEditVehicle CREATED");
            window.edit = this.editVehicle
        }        
    }
</script>

<template>

    <popover id="editVehicle" :title="editVehicle.id?'Edit Vehicle':'Add Vehicle'" :icon="editVehicle.type">

        <div class="field">
            <h3>Vehicle Type</h3>                
            <select-buttons :items="vehicleTypes" v-model="editVehicle.type"></select-buttons> 
        </div>  

        <div class="field" v-if="editVehicle.type=='bus'">
            <h3>Bus Type</h3>                
            <select-buttons :items="busTypes" v-model="editVehicle.subType"></select-buttons> 
        </div>         

        <div class="field">
            <h3>Identifier</h3>                
            <input type="text" required v-model="editVehicle.internalIdentifier">
        </div>  

        <div class="field" v-if="editVehicle.type=='bus'">
            <h3>Registration Number</h3>                
            <input type="text" class="uppercase" v-model="editVehicle.registrationNumber">
        </div>    

        <div class="cols">
            <div class="field">
                <h3>Manufacturer</h3>                
                <input type="text" v-model="editVehicle.manufacturer">
            </div>   
            <div class="field">
                <h3>Model</h3>                
                <input type="text" v-model="editVehicle.model">
            </div>                                  
        </div>  

        <div class="cols uneven">       
            <button @click="closePopover()" class="outline">Cancel</button>                                    
            <button @click="saveVehicle()" class="green">Save</button>                                     
        </div>           

    </popover>


</template>