export default { 
    data() {
        return {
            beaconBaseUUID:"55FF336E-DF4B-46C5-A49D-7403A537A1",
            uuidOptions:["00","01","02","03","04","05","06","07","08","09"],
            powerOptions:["5cm","0.5m", "0.8m", "1.5m", "2m", "7m","15m","20m","25m","45m","70m","100m"],
            powerMrssi:[-111,-106,-101,-96,-91,-87,-83,-79,-75,-71,-66,-63],
            //transmitIntervals:["100ms","152.5ms","211.25ms","318.75ms","417.5ms","546.25ms","760ms","852.5ms","1022.5ms","1285ms"],
            transmitIntervals:["10hz","6.5hz","4.7hz","3.1hz","2.4hz","1.8hz","1.3hz","1.2hz","1hz","0.8hz"],
            telemetryIntervals:["1 of 1", "1 of 5", "1 of 10", "1 of 100"],
            rangedBeacon:{},
            rangedBeacons:[],
            beaconRanging:false,
            beaconSearching:false,
            currentBeaconSerial: "",
            currentBeaconData:null,
            currentBeaconUUID:"",
            configLog:"",
            output:"" 
        }
    },
    methods : {
        getDefaultInstallationSettings:function() {
            return {
                "sensorType":2,
                "telemetryInterval":2                
            }
        },
        getDefaultConfigSettings:function(type) {
            var sensorSettings = {
                "name": "",
                "description": "",
                "password": "2012",
                "hardwareId": 1,
                "firmware": "",
                "sensorType": 2,
                "sensorState": 1,
                "imageBase64": "",
                "latitude": 0,
                "longitude": 0,
                "temperatureInterval": 0,
                "telemetryInterval": this.defaultSensorSettings[type].telemetryInterval,                
                "transmitPower": this.defaultSensorSettings[type].transmitPower,
                "advertisingInterval": this.defaultSensorSettings[type].advertisingInterval,
                "mrssi": this.powerMrssi[this.defaultSensorSettings[type].transmitPower]
            }
            if (type=="bus") {
                var sensors = [Object.assign({}, sensorSettings), Object.assign({}, sensorSettings)];
                sensors[0].name = "Front";
                sensors[1].name = "Back";
                return sensors;
            }
        },
        logConfigHandler:function(jsonLog) {
            console.warn(jsonLog);
            var logData = jsonLog;
            if (typeof jsonLog=="string") logData = JSON.parse(jsonLog);
            if (logData.failed) this.configLog += ("ERROR:" + logData.message + "\n");
            this.configLog += (logData.message + "\n");
        },
        configBeacon:function() {
            this.configLog = "";
            window.beaconconfig.configBeacon(this.currentBeaconData.serialNumber, this.currentBeaconData, this.logConfigHandler);
        },
        toggleRangeBeacons:function() {
            if (!this.beaconRanging) {
                this.beaconRanging = true;
                this.startBeaconRanging();
            }
            else {
                this.beaconRanging = false;
                this.stopBeaconRanging();
            }
        },
        startBeaconRanging : function() {
            this.currentBeaconData = null;
            this.rangedBeacons = [];
            this.configLog = "";
            window.beaconconfig.startBeaconRanging(this.setBeaconRangeData);
        },
        stopBeaconRanging : function() {
            this.currentBeaconData = null;
            window.beaconconfig.stopBeaconRanging();
        },
        qrscanBeaconSerial : function() {
            this.output = "";
            window.beaconconfig.scanSerial(this.scanBeaconSerialResult);
        },
        getBeaconConfigBySerial : function() {
            this.configLog = "";
            this.currentBeaconData = null;
            if (this.currentBeaconSerial) window.beaconconfig.getBeaconConfigBySerial(this.currentBeaconSerial, this.readBeaconResult);
        },
        getBeaconConfigByScan : function() {
            this.configLog = "";
            window.beaconconfig.getBeaconConfigByScan(this.readBeaconResult);
        },
        resetBeacon : function() {
            this.configLog = "";
            if (this.currentBeaconSerial) window.beaconconfig.resetBeacon(this.currentBeaconSerial, this.logConfigHandler);
        },
        readBeaconResult : function(beaconData) {
            this.currentBeaconData = beaconData;
            this.currentBeaconSerial = this.currentBeaconData.serialNumber;
            this.currentBeaconData.uuid_short = this.currentBeaconData.uuid.slice(-2);
            var jsonDataFormatted = JSON.stringify(this.currentBeaconData, null, 2);
            this.output = jsonDataFormatted;
        },
        scanBeaconSerialResult : function(serialNumber) {
            this.currentBeaconSerial = serialNumber;
        },
        setBeaconSearching:function(isSearching) {
            this.beaconSearching = isSearching;
        },
        setBeaconRangeData:function(beacon) {
            
            if (this.beaconSearching) {
                this.rangedBeacon = beacon;
            }
            
            if (this.beaconRanging) {
                var existingBeacon = null;
                for (var i=0; i<this.rangedBeacons.length; i++) {
                    if (this.rangedBeacons[i].serialNumber==beacon.serialNumber) existingBeacon = this.rangedBeacons[i];
                }
                if (existingBeacon==null) {
                    beacon.count = 1;
                    this.rangedBeacons.push(beacon);
                }
                else {
                    existingBeacon.rssi = beacon.rssi;
                    existingBeacon.count++;
                }
                this.rangedBeacons.sort(function(a,b) {
                    if (a.rssi==b.rssi) return 0;
                    if (a.rssi<b.rssi) return 1;
                    else return -1;
                });
            }
            console.log(this.rangedBeacons);
        },
    }
 }