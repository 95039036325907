class Languages {
    
    constructor() {
        this.strings = {
            "no": {               
                "login": {
                    "statusbarTitle":"Login",
                    "title":"Pinch Sensor Admin",
                    "text":"Sensor utrulling og konfigurasjon", 
                    "button":"Logg inn"       
                },       
                "alerts": {
                    "nointernett": {
                        "title": "Nettverk ikke tilgjengelig",
                        "message": "Applikasjonen vil fortsette med redusert funksjonalitet. Innholdet vil ikke alltid oppdateres med riktig informasjon."
                    },
                    "communicationError": {
                        "title": "Kommunikasjonsfeil",
                        "message": "Appen kunne ikke laste ned nødvendig innhold. Vennligst kontroller din internettforbindelse og prøv igjen .."
                    },          
                    "unauthorized": {
                        "title":"Uautorisert",
                        "message":"<strong>Kunne ikke logge inn</strong>, vennligst verifiser ditt <strong>Intervjunummer</strong> og eller <strong>Passord.</strong>"
                    },
                    "networkerror": {
                        "title":"Nettverksfeil",
                        "message":"<strong>Kunne ikke logge inn</strong>, vennligst kontroller din nettverkstilkobling."
                    },
                    "error": {
                        "title":"Ops..",
                        "message":"Uheldigvis gikk noe galt. Hvis problemet vedvarer, vennligst ta kontakt med din administrator."
                    },           
                },
                "dialogs": {
                    "homescreen": {
                        "title":"Start fra Hjemskjerm",
                        "text":"<p>For den beste opplevelsen bør du installere denne webappen på hjemskjermen og starte den derfra. </p><p>Hvis du har allerede har installert, må du gå ut av nettleseren og starte webappen fra hjemskjermen.</p>",
                        "buttons": {
                            "cancel":"Avbryt",
                            "install":"Installer"
                        }
                    },           
                }        
            },
        }
    }

    appStrings() {
        return this.strings["no"];
        // var languageCode = navigator.language.slice(0, 2);
        // if (new URLSearchParams(document.location.search).get("lan")) languageCode = new URLSearchParams(document.location.search).get("lan");
        // if (languageCode == "nb") languageCode = "no";
        // if (languageCode!="en" && languageCode!="no") languageCode = "en";
        // languageCode = "no";        
        // return this.strings[languageCode];
    }

}

export default new Languages()

